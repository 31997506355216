import * as React from "react";
import 'assets/scss/app.scss';
import Cursor from "../components/Cursor";
import {ContextProviderComponent} from "../components/Context";
import {isIOS} from "../components/Helpers/Device";
import {Helmet} from "react-helmet";

interface LayoutProps {
    children: any
    playTransition?: any
}

const Layout = ({children} : LayoutProps) => {

    return (
        <ContextProviderComponent>
            <Cursor />
            <Helmet>
                <body className={isIOS() ? 'is-ios' : ''} />
            </Helmet>

            <div className={"c-layout"}>
                {children}
            </div>
        </ContextProviderComponent>
    )
}

export default Layout;
