import * as React from "react";
import {useState} from "react";
import Cursor from "./Cursor";

const initialState = {
    cursorState: 0,
    setCursorState: null,
    playTransition: false,
    setPlayTransition: null,
    fadeOut: false,
    setFadeOut: null,
}

const GlobalContext = React.createContext(initialState)
const { Provider, Consumer } = GlobalContext

const ContextProviderComponent = (props) => {
    const [cursorState, setCursorState] = useState(Cursor.THUNDER);
    const [playTransition, setPlayTransition] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);

    const value = {
        cursorState, setCursorState,
        playTransition, setPlayTransition,
        fadeOut, setFadeOut,
    }

    return <Provider value={value}>{props.children}</Provider>
}

export { Consumer as default, ContextProviderComponent, GlobalContext}
